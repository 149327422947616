export default [
    {
        path: '/sales/quotations',
        name: 'request-quotations',
        props: true,
        component: () => import('@/views/request/quotations/index.vue'),
        meta: {
            pageTitle: 'Permintaan Penawaran',
            breadcrumb: [
                {
                text: 'Permintaan Penawaran',
                active: true,
                },
            ],
        },
    },
    {
        path: '/sales/quotation/:id',
        name: 'detail-quotation',
        props: true,
        component: () => import('@/views/request/quotations/detail.vue'),
        meta: {
            pageTitle: 'Permintaan Penawaran',
            breadcrumb: [
                {
                text: 'Permintaan Penawaran',
                to: { name: 'request-quotations' },
                },
                {
                text: 'Detail Permintaan Penawaran',
                active: true,
                },
            ],
        },
    },
]
export default [
    {
        path: '/promotions',
        name: 'promotions',
        props: true,
        component: () => import('@/views/promotion/index.vue'),
        meta: {
            pageTitle: 'Promosi',
            breadcrumb: [
                {
                text: 'Promosi',
                active: true,
                },
            ],
        },
    },
    {
        path: '/promotion/add',
        name: 'create-promotion',
        component: () => import('@/views/promotion/create.vue'),
        meta: {
            pageTitle: 'Buat Promosi Baru',
            breadcrumb: [
                {
                text: 'Promosi',
                to: { name: 'promotions' },
                },
                {
                text: 'Buat Promosi Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/promotion/:id',
        name: 'detail-promotion',
        props: true,
        component: () => import('@/views/promotion/detail.vue'),
        meta: {
            pageTitle: 'Detail Promotion',
            breadcrumb: [
                {
                text: 'Promotions',
                to: { name: 'promotions' },
                },
                {
                text: 'Detail Promotion',
                active: true,
                },
            ],
        },
    },
]
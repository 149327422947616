export default [
    {
        path: '/settings/reminder',
        name: 'reminder',
        props: true,
        component: () => import('@/views/settings/reminder/index.vue'),
        meta: {
            pageTitle: 'Pengaturan',
            breadcrumb: [
                {
                text: 'Pengingat',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/document',
        name: 'documents',
        props: true,
        component: () => import('@/views/settings/terms/index.vue'),
        meta: {
            pageTitle: 'Pengaturan',
            breadcrumb: [
                {
                text: 'Dokumen',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/document/add',
        name: 'create-document',
        component: () => import('@/views/settings/terms/create.vue'),
        meta: {
            pageTitle: 'Pengaturan',
            breadcrumb: [
                {
                text: 'Dokumen',
                to: { name: 'documents' },
                },
                {
                text: 'Tambah Dokumen',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/contacts',
        name: 'contacts',
        props: true,
        component: () => import('@/views/settings/contact/index.vue'),
        meta: {
            pageTitle: 'Pengaturan',
            breadcrumb: [
                {
                text: 'Kontak',
                active: true,
                },
            ],
        },
    },
    {
        path: '/settings/theme',
        name: 'theme',
        props: true,
        component: () => import('@/views/settings/theme/index.vue'),
        meta: {
            pageTitle: 'Pengaturan',
            breadcrumb: [
                {
                text: 'Tema',
                active: true,
                },
            ],
        },
    },
]
export default [
    {
        path: '/emergencies',
        name: 'emergencies',
        props: true,
        component: () => import('@/views/emergencies/index.vue'),
        meta: {
            pageTitle: 'Darurat',
            breadcrumb: [
                {
                text: 'Darurat',
                active: true,
                },
            ],
        },
    },
    {
        path: '/emergency/:id',
        name: 'detail-emergency',
        props: true,
        component: () => import('@/views/emergencies/detail.vue'),
        meta: {
            pageTitle: 'Detail Darurat',
            breadcrumb: [
                {
                text: 'Darurat',
                to: { name: 'emergencies' },
                },
                {
                text: 'Detail Darurat',
                active: true,
                },
            ],
        },
    },
]
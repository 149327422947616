export default [
    {
        path: '/request/towings',
        name: 'request-towings',
        props: true,
        component: () => import('@/views/request/towings/index.vue'),
        meta: {
            pageTitle: 'Permintaan Towing',
            breadcrumb: [
                {
                text: 'Permintaan Towing',
                active: true,
                },
            ],
        },
    },
    {
        path: '/request/towing/:id',
        name: 'detail-towing',
        props: true,
        component: () => import('@/views/request/towings/detail.vue'),
        meta: {
            pageTitle: 'Permintaan Towing',
            breadcrumb: [
                {
                text: 'Permintaan Towing',
                to: { name: 'request-towings' },
                },
                {
                text: 'Detail Permintaan Towing',
                active: true,
                },
            ],
        },
    },
]
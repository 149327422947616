export default [
    {
        path: '/request/insurance-claims',
        name: 'request-insurance-claims',
        props: true,
        component: () => import('@/views/request/insurance-claims/index.vue'),
        meta: {
            pageTitle: 'Klaim Asuransi',
            breadcrumb: [
                {
                text: 'Klaim Asuransi',
                active: true,
                },
            ],
        },
    },
    {
        path: '/request/insurance-claim/:id',
        name: 'detail-insurance-claim',
        props: true,
        component: () => import('@/views/request/insurance-claims/detail.vue'),
        meta: {
            pageTitle: 'Klaim Asuransi',
            breadcrumb: [
                {
                text: 'Klaim Asuransi',
                to: { name: 'request-insurance-claims' },
                },
                {
                text: 'Detail Klaim Asuransi',
                active: true,
                },
            ],
        },
    },
]
export default [
    {
        path: '/customers',
        name: 'customers',
        props: true,
        component: () => import('@/views/customers/index.vue'),
        meta: {
            pageTitle: 'Pelanggan',
            breadcrumb: [
                {
                text: 'Pelanggan',
                active: true,
                },
            ],
        },
    },
    {
        path: '/customer/add',
        name: 'create-customer',
        component: () => import('@/views/customers/create.vue'),
        meta: {
            pageTitle: 'Buat Pelanggan Baru',
            breadcrumb: [
                {
                text: 'Pelanggan',
                to: { name: 'customers' },
                },
                {
                text: 'Buat Pelanggan Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/customer/:id',
        name: 'detail-customer',
        props: true,
        component: () => import('@/views/customers/detail.vue'),
        meta: {
            pageTitle: 'Detail Pelanggan',
            breadcrumb: [
                {
                text: 'Pelanggan',
                to: { name: 'customers' },
                },
                {
                text: 'Detail Pelanggan',
                active: true,
                },
            ],
        },
    },
]
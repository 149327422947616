export default [
    {
        path: '/place/charging-stations',
        name: 'place-charging-stations',
        props: true,
        component: () => import('@/views/place/charging-station/index.vue'),
        meta: {
            pageTitle: 'SPKLU',
            breadcrumb: [
                {
                text: 'SPKLU',
                active: true,
                },
            ],
        },
    },
    {
        path: '/place/charging-station/add',
        name: 'create-charging-station',
        component: () => import('@/views/place/charging-station/create.vue'),
        meta: {
            pageTitle: 'Buat SPKLU Baru',
            breadcrumb: [
                {
                text: 'SPKLU',
                to: { name: 'place-charging-stations' },
                },
                {
                text: 'Buat SPKLU Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/place/charging-station/:id',
        name: 'detail-charging-station',
        props: true,
        component: () => import('@/views/place/charging-station/detail.vue'),
        meta: {
            pageTitle: 'Detail SPKLU',
            breadcrumb: [
                {
                text: 'SPKLU',
                to: { name: 'place-charging-stations' },
                },
                {
                text: 'Detail SPKLU',
                active: true,
                },
            ],
        },
    },
    {
        path: '/place/workshops',
        name: 'place-workshops',
        props: true,
        component: () => import('@/views/place/workshop/index.vue'),
        meta: {
            pageTitle: 'Bengkel',
            breadcrumb: [
                {
                text: 'Bengkel',
                active: true,
                },
            ],
        },
    },
    {
        path: '/place/workshop/add',
        name: 'create-workshop',
        component: () => import('@/views/place/workshop/create.vue'),
        meta: {
            pageTitle: 'Buat Bengkel Baru',
            breadcrumb: [
                {
                text: 'Bengkel',
                to: { name: 'place-workshops' },
                },
                {
                text: 'Buat Bengkel Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/place/workshop/:id',
        name: 'detail-workshop',
        props: true,
        component: () => import('@/views/place/workshop/detail.vue'),
        meta: {
            pageTitle: 'Detail Bengkel',
            breadcrumb: [
                {
                text: 'Bengkel',
                to: { name: 'place-workshops' },
                },
                {
                text: 'Detail Bengkel',
                active: true,
                },
            ],
        },
    },
]
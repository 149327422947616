export default [
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/index.vue'),
        meta: {
            pageTitle: 'Profil',
            breadcrumb: [
                {
                text: 'Profil',
                active: true,
                },
            ],
        },
    },
]
export default [
    {
        path: '/catalogs',
        name: 'catalogs',
        props: true,
        component: () => import('@/views/catalog/index.vue'),
        meta: {
            pageTitle: 'Katalog',
            breadcrumb: [
                {
                text: 'Katalog',
                active: true,
                },
            ],
        },
    },
    {
        path: '/catalog/add',
        name: 'create-catalog',
        component: () => import('@/views/catalog/create.vue'),
        meta: {
            pageTitle: 'Buat Catalog Baru',
            breadcrumb: [
                {
                text: 'Katalog',
                to: { name: 'catalogs' },
                },
                {
                text: 'Buat Catalog Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/catalog/:id',
        name: 'detail-catalog',
        props: true,
        component: () => import('@/views/catalog/detail.vue'),
        meta: {
            pageTitle: 'Detail Katalog',
            breadcrumb: [
                {
                text: 'Katalog',
                to: { name: 'catalogs' },
                },
                {
                text: 'Detail Katalog',
                active: true,
                },
            ],
        },
    },
]
export default [
    {
        path: '/user/roles',
        name: 'roles',
        props: true,
        component: () => import('@/views/user/roles/index.vue'),
        meta: {
            pageTitle: 'Roles',
            breadcrumb: [
                {
                text: 'Roles',
                active: true,
                },
            ],
        },
    },
    {
        path: '/user/roles/add',
        name: 'create-roles',
        component: () => import('@/views/user/roles/create.vue'),
        meta: {
            pageTitle: 'Create Roles',
            breadcrumb: [
                {
                text: 'Roles',
                to: { name: 'roles' },
                },
                {
                text: 'Create Roles',
                active: true,
                },
            ],
        },
    },
    {
        path: '/user/roles/:id',
        name: 'detail-roles',
        props: true,
        component: () => import('@/views/user/roles/detail.vue'),
        meta: {
            pageTitle: 'Detail Roles',
            breadcrumb: [
                {
                text: 'Roles',
                to: { name: 'roles' },
                },
                {
                text: 'Detail Roles',
                active: true,
                },
            ],
        },
    },
]
export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
            pageTitle: 'Beranda',
            breadcrumb: [
                {
                text: 'Beranda',
                active: true,
                },
            ],
        },
    },
]
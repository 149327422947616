export default [
    {
        path: '/contracts',
        name: 'contracts',
        props: true,
        component: () => import('@/views/contract/index.vue'),
        meta: {
            pageTitle: 'Kontrak',
            breadcrumb: [
                {
                text: 'Kontrak',
                active: true,
                },
            ],
        },
    },
    {
        path: '/contract/add',
        name: 'create-contract',
        component: () => import('@/views/contract/create.vue'),
        meta: {
            pageTitle: 'Buat Kontrak Baru',
            breadcrumb: [
                {
                text: 'Kontrak',
                to: { name: 'contracts' },
                },
                {
                text: 'Buat Kontrak Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/contract/:id',
        name: 'detail-contract',
        props: true,
        component: () => import('@/views/contract/detail.vue'),
        meta: {
            pageTitle: 'Detail Kontrak',
            breadcrumb: [
                {
                text: 'Kontrak',
                to: { name: 'contracts' },
                },
                {
                text: 'Detail Kontrak',
                active: true,
                },
            ],
        },
    },
]
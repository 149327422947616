export default [
    {
        path: '/request/services',
        name: 'request-services',
        props: true,
        component: () => import('@/views/request/services/index.vue'),
        meta: {
            pageTitle: 'Permintaan Service',
            breadcrumb: [
                {
                text: 'Permintaan Service',
                active: true,
                },
            ],
        },
    },
    {
        path: '/request/service/:id',
        name: 'detail-service',
        props: true,
        component: () => import('@/views/request/services/detail.vue'),
        meta: {
            pageTitle: 'Permintaan Service',
            breadcrumb: [
                {
                text: 'Permintaan Service',
                to: { name: 'request-services' },
                },
                {
                text: 'Detail Permintaan Service',
                active: true,
                },
            ],
        },
    },
]
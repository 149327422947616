export default [
    {
        path: '/request/part-replacements',
        name: 'request-part-replacements',
        props: true,
        component: () => import('@/views/request/part-replacements/index.vue'),
        meta: {
            pageTitle: 'Penggantian Part',
            breadcrumb: [
                {
                text: 'Penggantian Part',
                active: true,
                },
            ],
        },
    },
    {
        path: '/request/part-replacement/:id',
        name: 'detail-part-replacement',
        props: true,
        component: () => import('@/views/request/part-replacements/detail.vue'),
        meta: {
            pageTitle: 'Penggantian Part',
            breadcrumb: [
                {
                text: 'Penggantian Part',
                to: { name: 'request-part-replacements' },
                },
                {
                text: 'Detail Penggantian Part',
                active: true,
                },
            ],
        },
    },
]